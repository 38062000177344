import { AppBrandings } from '../../common/utils/data/appBrandings';

const getFileLink = (type, domain, locale) => {
  const branding = domain === AppBrandings.ANAC ? 'LubAnac' : 'Diagotrucks';
  let localeFolder;

  switch (locale) {
    case 'fr':
      localeFolder = 'fr';
      break;

    default:
      localeFolder = 'en';
      break;
  }

  return `/footer/${localeFolder}/${type}-${branding}.pdf`;
};

export const getFooterLinks = (domain, locale, i18n) => {
  const anacFooterLinks = [{
    href: getFileLink('GTCU', domain, locale),
    label: i18n._(`Layout.footer.GTCU`),
    target: '_blank'
  }, {
    href: getFileLink('PersonalDataandCookies', domain, locale),
    label: i18n._(`Layout.footer.personalDataAndCookies`),
    target: '_blank'
  }, {
    href: 'https://lubricants.totalenergies.com/business/oil-diagnosis-anac',
    label: i18n._(`Layout.footer.totalLubs`),
    target: '_blank'
  }];
  const diagotrucksFooterLinks = [{
    href: getFileLink('GTCU', domain, locale),
    label: i18n._(`Layout.footer.GTCU`),
    target: '_blank'
  }, {
    href: getFileLink('PersonalDataandCookies', domain, locale),
    label: i18n._(`Layout.footer.personalDataAndCookies`),
    target: '_blank'
  }, {
    href: 'https://www.renault-trucks-oils.com',
    label: i18n._(`Layout.footer.diagotrucksOfficialWebsite`),
    target: '_blank'
  }];
  return domain === AppBrandings.ANAC ? anacFooterLinks : diagotrucksFooterLinks;
};